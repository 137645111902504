<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <router-link to="/notifications">Notifications</router-link> >
      Notification details
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">Notification</h4>
          <p class="category">
            {{ notification.timestamp }}
          </p>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-medium-size-100">
              <h5>{{ notification.message }}</h5>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    notification: {},
  }),
  mounted() {
    this.$store
      .dispatch("notification/getNotification", this.$route.params.notification)
      .then((notification) => (this.notification = notification));
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  watch: {
    "$route.params.notification"() {
      this.$store
        .dispatch(
          "notification/getNotification",
          this.$route.params.notification
        )
        .then((notification) => (this.notification = notification));
    },
  },
};
</script>
